<template>
  <b-row class="match-height">
    <b-col md="6">
      <apex-radial-bar-chart />
    </b-col>
    <b-col md="6">
      <apex-radar-chart />
    </b-col>
    <b-col md="12">
      <apex-donut-chart />
    </b-col>
    <b-col cols="12">
      <apex-scatter-chart />
    </b-col>
    <b-col md="6">
      <apex-bar-chart />
    </b-col>

  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import ApexScatterChart from './ApexScatterChart.vue'

import ApexDonutChart from './ApexDonutChart.vue'
import ApexRadialBarChart from './ApexRadialBarChart.vue'
import ApexRadarChart from './ApexRadarChart.vue'

export default {
  components: {
    BRow,
    BCol,

    ApexScatterChart,
    ApexDonutChart,
    ApexRadarChart,
    ApexRadialBarChart,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
